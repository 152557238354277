import React, { useState, useEffect, useRef } from 'react';
import "./adsmid.css";
import mbad2 from "./midbtmad2.jpeg";

const AdsMidBottom3 = () => {
  const [ads, setAds] = useState([]);
  const adContainerRef = useRef(null);

  const handleAdClick = async (ad) => {
    if (ad && ad._id) {
      try {
        await fetch(`https://api.sigortagundem.com/api/ads/${ad._id}/hit`, {
          method: 'POST',
        });
        window.open(ad.link, '_blank');
      } catch (error) {
        console.error("Error incrementing hit count:", error);
      }
    }
  };

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch('https://api.sigortagundem.com/api/ads');
        const data = await response.json();

        const filteredAds = data.filter(ad =>
          ad.title.includes("Sidemenü Reklam") && ad.isActive === true
        );

        setAds(filteredAds);
      } catch (error) {
        console.error("Reklamlar çekilemedi:", error);
      }
    };

    fetchAds();
  }, []);

  useEffect(() => {
    if (ads.length > 0 && adContainerRef.current) {
      adContainerRef.current.innerHTML = ''; // Clear previous content

      ads.slice(0, 4).forEach((ad) => {
        const adItem = document.createElement('div');
        adItem.className = 'ad-item';

        if (ad.imageURL.includes('doubleclick.net') || ad.imageURL.includes('sadbundle')) {
          const iframe = document.createElement('iframe');
          iframe.src = ad.imageURL;
          iframe.width = '300';
          iframe.height = '300';
          iframe.frameBorder = '0';
          iframe.scrolling = 'no';
          iframe.style = 'border: 0px; vertical-align: bottom; margin: 20px auto';
          if (ad.imageURL.includes('sadbundle')) {
            iframe.allowFullscreen = true;
          }
          adItem.appendChild(iframe);
        } else {
          const link = document.createElement('a');
          link.href = "#";
          link.onclick = () => handleAdClick(ad); // Click handler

          const img = document.createElement('img');
          img.src = ad.imageURL || mbad2;
          img.alt = ad.title || "Default Advertisement";
          img.style = 'width: 300px; height: 300px; object-fit: contain; margin: 20px auto';

          link.appendChild(img);
          adItem.appendChild(link);
        }

        adContainerRef.current.appendChild(adItem);
      });
    }
  }, [ads]);

  return (
    <div>
      <div className='mid-bottom-ads-container2' ref={adContainerRef}>
        {/* Ad content will be dynamically inserted here */}
      </div>
    </div>
  );
};

export default AdsMidBottom3;
