import React, { useState, useEffect } from "react";
import "./adsmid.css";
import mbad2 from "./kaskolamag.jpeg";

const AdContainer = ({ ad, defaultImage, className, width, height, handleClick }) => {
  const isIframe = ad && (ad.imageURL.includes("doubleclick.net") || ad.imageURL.includes("sadbundle"));

  if (!ad) return null;

  const handleAdClick = async () => {
    if (ad && ad._id) {
      try {
        await fetch(`https://api.sigortagundem.com/api/ads/${ad._id}/hit`, {
          method: 'POST',
        });
        window.open(ad.link || '#', '_blank');
      } catch (error) {
        console.error("Error incrementing hit count:", error);
      }
    }
  };

  return (
    <a
      href="#"
      onClick={handleAdClick}
      className={`ad-container ${className}`}
    >
      {isIframe ? (
        <iframe
          src={ad.imageURL}
          width={width}
          height={height}
          frameBorder="0"
          scrolling="no"
          style={{ border: 0, verticalAlign: "bottom" }}
          allowFullScreen={ad.imageURL.includes("sadbundle")}
          title={ad.title || "Advertisement"}
        />
      ) : (
        <img
          src={ad.imageURL || defaultImage}
          alt={ad.title || "Advertisement"}
          style={{ width, height }}
        />
      )}
    </a>
  );
};

const AdsMidTop = () => {
  const [adLeft, setAdLeft] = useState(null);
  const [adRight, setAdRight] = useState(null);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch("https://api.sigortagundem.com/api/ads");
        const data = await response.json();

        const leftAd = data.find(
          (ad) =>
            ad.title.includes("Dergiler Altında SOL KARE Banner") &&
            ad.isActive === true
        );

        const rightAd = data.find(
          (ad) =>
            ad.title.includes("Dergiler Altında Sol Kare Bannerın SAĞ YANI") &&
            ad.isActive === true
        );

        setAdLeft(leftAd);
        setAdRight(rightAd);

        if (leftAd) {
          await fetch(`https://api.sigortagundem.com/api/ads/${leftAd._id}/hit`, {
            method: 'POST',
          });
        }
        if (rightAd) {
          await fetch(`https://api.sigortagundem.com/api/ads/${rightAd._id}/hit`, {
            method: 'POST',
          });
        }
      } catch (error) {
        console.error("Error fetching advertisement data:", error);
      }
    };

    fetchAds();
  }, []);

  return (
    <div className="mid-top-ads-container1">
      <AdContainer ad={adLeft} defaultImage={mbad2} className="ad-left" width="300px" height="300px" />
      <AdContainer ad={adRight} defaultImage={mbad2} className="ad-right" width="970px" height="250px" />
    </div>
  );
};

export default AdsMidTop;
